import Vue
    from "vue";
import Vuex
    from "vuex";
// import createPersistedState from "vuex-persistedstate";
// import * as Cookies from "js-cookie";
// import VuexPersistence
//     from 'vuex-persist'
//import modules
import Catalogue
    from "./modules/catalogue";
import HeadlesCms
    from "./modules/headless_cms";
import Organization
    from "./modules/organization";
import Pos
    from "./modules/pos";
import Profile
    from "./modules/profile";
import Support
    from "./modules/support";
import Backend
    from "./modules/backend";
import Warehouse
    from "./modules/warehouse";
import Workflow
    from "./modules/workflow";
import Reports
    from "./modules/reports";
import Search
    from "./modules/search";
import Files
    from "./modules/files";
import Supplier
    from "./modules/supplier";
import Wishlist from "./modules/wishlist";

import Announcement from "./modules/announcement";
// import Auth
//     from "./modules/auth";

// const vuexLocal = new VuexPersistence({
//     storage: window.localStorage,
//     modules: [
//         // 'Catalogue',
//         'HeadlesCms',
//         'Organization',
//         'Pos',
//         'Profile',
//         'Support',
//         'Backend',
//         'Warehouse',
//         'Workflow',
//         'Reports',
//         'Search',
//         'Auth',
//     ]
// })

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Catalogue,
        HeadlesCms,
        Organization,
        Pos,
        Profile,
        Support,
        Backend,
        Warehouse,
        Workflow,
        Reports,
        Search,
        Files,
        Supplier,
        Wishlist,
        Announcement,
    },
    plugins: [
        /*createPersistedState({
         storage: {
           getItem: (key) => Cookies.get(key),
           // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
           setItem: (key, value) =>
             Cookies.set(key, value, { expires: 3, secure: true }),
           removeItem: (key) => Cookies.remove(key),
         },
       }),*/
        // vuexLocal.plugin
    ],
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: "#2b2b2b", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
        SidebarBg: "",
        navbarColor: "#2b2b2b",
        darkmode: true,
        setHorizontalLayout: false, // Horizontal layout
    },
    mutations: {
        //syncronys
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload;
           
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload;
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload;
        },
        SET_NAVBAR_COLOR(state, payload) {
            state.navbarColor = payload;
        },
        SET_LAYOUT(state, payload) {
            state.setHorizontalLayout = payload;
        },
    },
    actions: {
        // asyncronys
    },
    getters: {},
});
